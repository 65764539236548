<template>
    <div>
        <div v-if="$route.query.error" class="container pt-md-4">
            <div class="alert alert-danger">
                <Icon v="heart-broken" />
                <T>user.login.oauthError</T>
            </div>
        </div>
        <HomepageRoute v-if="config.header" />
        <SelectVersionRoute v-else />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useConfig from '../composables/useConfig.ts';
import HomepageRoute from '../routes/homepage.vue';
import SelectVersionRoute from '../routes/select.vue';

export default defineComponent({
    components: { HomepageRoute, SelectVersionRoute },
    setup() {
        return {
            config: useConfig(),
        };
    },
});
</script>
