<script setup lang="ts">
const config = useConfig();

interface Link {
    icon: string;
    header: string;
    name: string;
}

const mainLinks: Link[] = [];
if (config.pronouns.enabled) {
    mainLinks.push({
        icon: 'tags',
        header: 'pronouns.headerLong',
        name: 'pronouns',
    });
}
if (config.nouns.enabled) {
    mainLinks.push({
        icon: 'book',
        header: 'nouns.headerLong',
        name: 'nouns',
    });
}
if (config.user.enabled) {
    mainLinks.push({
        icon: 'id-card',
        header: 'profile.bannerButton',
        name: 'user',
    });
}

const { data: latestPosts } = useAsyncData('latest-posts', async () => {
    if (!config.blog) {
        return [];
    }
    return (await $fetch('/api/blog')).slice(0, 9);
}, {
    lazy: true,
});
const { data: featuredPosts } = useAsyncData('featured-posts', async () => {
    if (!config.blog?.shortcuts) {
        return [];
    }
    return await $fetch('/api/blog?shortcuts');
}, {
    lazy: true,
});
</script>

<template>
    <Page>
        <AdPlaceholder :phkey="['header', null]" />
        <template v-if="$te('home.welcome')">
            <section>
                <h2>
                    <T>home.welcome</T>
                </h2>

                <p>
                    <T>home.intro</T>
                </p>

                <div class="row">
                    <div
                        v-for="{ icon, header, name } in mainLinks"
                        :key="header"
                        :class="[mainLinks.length > 3 ? 'col-6 col-lg-3' : 'col', 'my-4', 'text-center']"
                    >
                        <nuxt-link :to="{ name }">
                            <p>
                                <Icon :v="icon" :size="2" class="d-inline-block d-lg-none" />
                                <Icon :v="icon" :size="3" class="d-none d-lg-inline-block" />
                            </p>
                            <p class="h5">
                                <Spelling :text="$t(header)" />
                            </p>
                        </nuxt-link>
                    </div>
                </div>
                <CalendarBanner link />

                <div
                    v-if="config.locale === 'en' && new Date() < new Date(2024, 5, 13, 0, 0, 0) && $route.path === '/'"
                    class="container"
                >
                    <div class="alert alert-light my-3">
                        <p class="h4">
                            <Icon v="chart-pie" />
                            Take part in the Gender Census 2024!
                        </p>
                        <p>
                            Our friend who's running the annual international Gender Census
                            is currently collecting answers in the 11th edition of the survey!
                            It's open until 13th June 2024.
                            It's for anyone whose gender (or lack thereof) isn't described by the M/F binary.
                            It's short and easy, and results are useful in academia, business and self-advocacy.
                        </p>
                        <p class="mb-0">
                            <a href="https://survey.gendercensus.com" target="_blank" rel="noopener" class="btn btn-primary">
                                <Icon v="arrow-alt-right" />
                                survey.gendercensus.com
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        </template>

        <section>
            <h2>
                <T>home.why</T>
            </h2>

            <T>home.about</T>

            <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />

            <CardsBanner />

            <CalendarBanner v-if="!$te('home.welcome')" link />
        </section>

        <Separator icon="fist-raised" />

        <Mission />

        <AdPlaceholder :phkey="['content-1', 'content-mobile-1']" />

        <Loading v-if="config.blog" :value="latestPosts">
            <template #header>
                <Separator icon="pen-nib" class="mb-5" />
                <h2 class="mb-3">
                    <Icon v="pen-nib" />
                    <T>links.blogLatest</T>
                </h2>
            </template>
            <BlogEntriesList :posts="latestPosts" />
        </Loading>

        <Separator icon="bookmark" />

        <section>
            <div class="row">
                <div class="col-12 col-md-6">
                    <LanguageVersions />
                </div>
                <div class="col-12 col-md-6">
                    <Socials />
                    <hr>
                    <section>
                        <Share />
                    </section>
                </div>
            </div>
        </section>

        <Loading v-if="config.blog?.shortcuts" :value="featuredPosts">
            <template #header>
                <Separator icon="pen-nib" class="mb-5" />
            </template>
            <BlogEntriesList :posts="featuredPosts" />
        </Loading>

        <Separator icon="heart" />
        <section>
            <Support />
            <Contribute />
        </section>
    </Page>
</template>
