<template>
    <div class="border rounded overflow-hidden list-group-item-bg">
        <div class="list-group d-flex flex-wrap flex-row list-group-flush">
            <template v-for="(options, locale) in locales">
                <a
                    v-if="filter === '' || options.matches(filter)"
                    :key="locale"
                    :href="options.url"
                    class="list-group-item list-group-item-action list-group-item-hoverable w-md-50 border-start"
                >
                    <div class="h3">
                        <LocaleIcon :locale="options" class="mx-2" />
                        {{ options.name }}
                        <small v-if="options.extra" class="text-muted">({{ options.extra }})</small>
                    </div>
                </a>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import type { LocaleDescription } from '~/locale/locales.ts';

export default defineComponent({
    props: {
        locales: { required: true, type: Object as PropType<Record<string, LocaleDescription>> },
        filter: { default: '', type: String },
    },
});
</script>
