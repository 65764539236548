<template>
    <Page>
        <section v-if="Object.keys(suggestedLocales).length > 0">
            <p class="mb-2">
                <Icon v="lightbulb" />
                Suggested languages, based on your browser settings:
            </p>
            <LocaleList :locales="suggestedLocales" />
        </section>

        <section>
            <FilterBar v-model="filter" />
            <LocaleList :locales="$locales" :filter="filter" />
        </section>

        <!--
        <template #below>
            <LanguageMap />
            <p class="small text-muted my-3">
                <Icon v="info-circle" />
                The complexity of geographical reach of each language is represented in a simplified way
                using locations as defined by the authors of
                <a href="https://wals.info/" target="_blank">The World Atlas of Language Structures</a>.
            </p>
        </template>
        -->
    </Page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import type { LocaleDescription } from '~/locale/locales.ts';

interface Refs {
    filter: HTMLInputElement | undefined;
}

export default defineComponent({
    data() {
        return {
            filter: '',
            suggestedLocales: {} as Record<string, LocaleDescription>,
        };
    },
    computed: {
        $tRefs(): Refs {
            return this.$refs as unknown as Refs;
        },
    },
    mounted() {
        const browserLocales = window.navigator.languages || [window.navigator.language || (window.navigator as any).userLanguage];

        const supportedLocaleCodes = browserLocales
            .map((code) => code.includes('-') ? code.split('-')[0] : code)
            .filter((code) => this.$locales.hasOwnProperty(code));

        this.suggestedLocales = Object.fromEntries(
            Array.from(new Set(supportedLocaleCodes))
                .map((code: string) => [code, this.$locales[code]]),
        );
    },
});
</script>

<style lang="scss" scoped>
    @import "assets/variables";

    .list-group-item-hoverable {
        &:hover {
            color: $primary;
            border-inline-start: 3px solid $primary;
            padding-inline-start: calc(#{$list-group-item-padding-x} - 2px);
        }
    }
</style>
